import Vue from "vue";
export default Vue.extend({
    props: {
        emailAddress: {
            type: String,
            required: true,
        },
        mobileNumber: {
            type: String,
            required: true,
        },
        status: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            honeyPotValue: "",
            form: {
                preferredContactMethod: "",
                weChatId: "",
                message: "",
            },
        };
    },
    computed: {
        contactMethodOptions: function () {
            return [
                { value: "e", label: "Email (".concat(this.emailAddress, ")") },
                { value: "m", label: "Mobile - call/text (".concat(this.mobileNumber, ")") },
                { value: "wa", label: "WhatsApp (".concat(this.mobileNumber, ")") },
                { value: "w", label: "WeChat ID" },
            ];
        },
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.$validator.validate().then(function (valid) {
                if (!valid) {
                    return;
                }
                _this.$emit("complete", _this.form);
            });
        },
    },
});
