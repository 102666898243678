var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import PersonalInformationForm from "./PersonalInformationForm.vue";
import SignInForm from "../auth/SignInForm.vue";
import { fromApiDate } from "../../api/transform";
import IsNewTabs from "./IsNewTabs.vue";
export default Vue.extend({
    components: {
        PersonalInformationForm: PersonalInformationForm,
        SignInForm: SignInForm,
        IsNewTabs: IsNewTabs,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        extraFields: {
            type: Array,
            required: true,
        },
    },
    apollo: {
        viewerStudent: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        {\n          viewerStudent {\n            id\n            firstName\n            lastName\n            emailAddress\n            passportNumber\n            passportCountry\n            passportExpiry\n            educationInstitute\n            dateOfBirth\n            gender\n            mobileNumber\n          }\n        }\n      "], ["\n        {\n          viewerStudent {\n            id\n            firstName\n            lastName\n            emailAddress\n            passportNumber\n            passportCountry\n            passportExpiry\n            educationInstitute\n            dateOfBirth\n            gender\n            mobileNumber\n          }\n        }\n      "]))),
            result: function (result) {
                if (!result.data || !result.data.viewerStudent) {
                    return;
                }
                var student = result.data.viewerStudent;
                if (this.value) {
                    this.$emit("input", {
                        firstName: this.value.firstName || student.firstName || "",
                        lastName: this.value.lastName || student.lastName || "",
                        emailAddress: this.value.emailAddress || student.emailAddress || "",
                        passportNumber: this.value.passportNumber || student.passportNumber || "",
                        passportCountry: this.value.passportCountry || student.passportCountry || "",
                        passportExpiry: this.value.passportExpiry ||
                            fromApiDate(student.passportExpiry) ||
                            "",
                        educationInstitute: this.value.educationInstitute || student.educationInstitute || "",
                        dateOfBirth: this.value.dateOfBirth || fromApiDate(student.dateOfBirth),
                        gender: this.value.gender || student.gender || "",
                        mobileNumber: this.value.mobileNumber || student.mobileNumber || "",
                        purchasingAgent: undefined,
                        saveForNextTime: false,
                    });
                }
            },
        },
    },
    data: function () {
        return {
            isNewSelected: true,
        };
    },
    methods: {
        onPersonalSubmit: function () {
            this.$emit("submit");
        },
    },
});
var templateObject_1;
