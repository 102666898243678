var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import gql from "graphql-tag";
import SignInOrPersonalInformationForm from "../../../ui/SignInOrPersonalInformationForm.vue";
import EnquiryForm from "./EnquiryForm.vue";
import PersonalInformationLoading from "../../general/PersonalInformationLoading.vue";
import AsyncStatus from "../../../../utils/async";
import accommodationEnquiry from "../../../../api/mutations/accommodationEnquiry";
import { createEmptyPersonalInformationEntry, isValidPersonalInformationEntry, } from "../../../../data";
import Vue from "vue";
import { rethrowPossibleApolloErrorAsUserMessage } from "../../../../api/apolloErrorToUserMessage";
export default Vue.extend({
    components: {
        SignInOrPersonalInformationForm: SignInOrPersonalInformationForm,
        EnquiryForm: EnquiryForm,
        PersonalInformationLoading: PersonalInformationLoading,
    },
    apollo: {
        publishedPropertyLeaseTermBySlugAndId: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query EnquireNowQuery($slug: String!, $leaseTermId: ID!) {\n          publishedPropertyLeaseTermBySlugAndId(\n            slug: $slug\n            leaseTermId: $leaseTermId\n          ) {\n            id\n            roomType {\n              propertyListing {\n                id\n                slug\n                streetAddress\n                citySlug\n                suburbName\n              }\n            }\n          }\n        }\n      "], ["\n        query EnquireNowQuery($slug: String!, $leaseTermId: ID!) {\n          publishedPropertyLeaseTermBySlugAndId(\n            slug: $slug\n            leaseTermId: $leaseTermId\n          ) {\n            id\n            roomType {\n              propertyListing {\n                id\n                slug\n                streetAddress\n                citySlug\n                suburbName\n              }\n            }\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    slug: this.$route.params.slug,
                    leaseTermId: this.$route.params.leaseTermId,
                };
            },
        },
    },
    data: function () {
        return {
            step: "personal",
            personalInformation: createEmptyPersonalInformationEntry(),
            submitStatus: new AsyncStatus(),
            publishedPropertyLeaseTermBySlugAndId: undefined,
        };
    },
    computed: {
        publishedProperty: function () {
            if (!this.publishedPropertyLeaseTermBySlugAndId) {
                return undefined;
            }
            return this.publishedPropertyLeaseTermBySlugAndId.roomType
                .propertyListing;
        },
        breadcrumbItems: function () {
            var items = [
                { name: this.$t("Accommodation"), link: "/accommodation" },
            ];
            if (!this.publishedProperty) {
                return items;
            }
            return __spreadArray(__spreadArray([], items, true), [
                {
                    name: this.publishedProperty.suburbName,
                    link: "/accommodation/".concat(this.publishedProperty.citySlug),
                },
                {
                    name: this.publishedProperty.streetAddress,
                    link: "/accommodation/listing/".concat(this.publishedProperty.slug),
                },
                {
                    name: this.$t("Enquire now"),
                },
            ], false);
        },
    },
    methods: {
        onPersonalInformationSubmit: function () {
            this.step = "enquiry";
        },
        onEnquiryComplete: function (enquiry) {
            var _this = this;
            if (!this.publishedPropertyLeaseTermBySlugAndId) {
                throw new Error("Invalid state (no published property)");
            }
            if (!this.personalInformation) {
                throw new Error("Invalid state (no personal information)");
            }
            if (!isValidPersonalInformationEntry(this.personalInformation)) {
                throw new Error("Invalid personal information");
            }
            var _a = this.personalInformation, saveForNextTime = _a.saveForNextTime, purchasingAgent = _a.purchasingAgent, personalInformationInput = __rest(_a, ["saveForNextTime", "purchasingAgent"]);
            this.submitStatus
                .run(this.$apollo
                .mutate(accommodationEnquiry(__assign({ leaseTerm: this.publishedPropertyLeaseTermBySlugAndId.id, personalInformation: personalInformationInput, purchasingAgent: purchasingAgent }, enquiry)))
                .catch(rethrowPossibleApolloErrorAsUserMessage))
                .then(function (response) {
                if (!_this.publishedProperty) {
                    throw new Error("Invalid state (no property slug)");
                }
                if (!response.data) {
                    throw new Error("Error trying to create enquiry");
                }
                var listingBaseUrl = "/accommodation/listing/".concat(_this.publishedProperty.slug, "/");
                if (saveForNextTime) {
                    _this.$router.push("".concat(listingBaseUrl, "enquiry-save-for-next/").concat(response.data.accommodationEnquiry.enquiryId));
                    return;
                }
                _this.$router.push("".concat(listingBaseUrl, "enquiry-submitted"));
            });
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Enquire now").toString(),
        };
    },
});
var templateObject_1;
